<!-- 联想词 -->
<template>
    <div class="associate-area">
        <ul class="v2-g-flex-column">
            <li v-for="(item,index) in list" @click="tojump(item.value)" class="link-color" :class="index==sel?'sel':''">
                <span v-if="item.valueHighlight!=null" v-html="item.valueHighlight"></span>
                <span v-else>{{item.value}}</span>
            </li>
        </ul>
    </div>
</template>
<style scoped lang="less">
 .associate-area{
    height: 100%;
    padding: 10px;
    ul{
        height: 100%;
        overflow-y: auto;
        li{
            &.sel{
                background-color: #5B9BF4;
                color:#fff;
            }
            height: 33px;
            line-height: 33px;
            text-align: left;
            cursor: pointer;

        }
    }
 }
</style>
<script>


export default{
    data(){
        return {
            list:[]
        };
    },
    created(){
        this.getAssociate();
    },
    props:{
        word:{type:String},
        sel:{type:Number}
    },
    watch:{
        'word':function(newV,oldV){
            this.getAssociate();
        }
    },
    methods: {
        async getAssociate() {
          if (this.word == null || this.word == '') {
            return;
          }
          let url = "/pjyy-deepexi-product-center/api/v3/product/PcProduct/lenovoSearch";
          const result = await this.$getHttpClient({useSpinner: false}).post(url, {name: this.word});
          if (result.data.code == '200') {
            this.list = result.data.data;
          }
        },
        tojump(item){
            this.$router.push({name:'v2-search',params:{q:item}})
        },
        tojump_enter(){

            if(this.sel>=0 && this.sel<this.list.length){
                this.$router.push({name:'v2-search',params:{q:this.list[this.sel].value}})
            }
        }
    }
}
</script>
