<template>
  <div>
    <div class="redmodel" v-if="isshowRedbag" @click="isshowRedbag = !isshowRedbag">
      <div class="item">
        <div class="item-head">2023年度让利</div>
        <div class ="tb">
          <table>
            <tr class="t-h">
              <td></td>
              <td>采购总额</td>
              <td>让利总额</td>
              <td>实付总额</td>
              <td>让利比例</td>
            </tr>
            <tr  v-for="(item, index) in summaryData">
              <td>{{ item.monthStr }}</td>
              <td>￥{{ item.totalMoney }}</td>
              <td>￥{{ item.discountMoney }}</td>
              <td>￥{{ item.actualMoney }}</td>
              <td>{{ item.rate }}%</td>
            </tr>

          </table>
        </div>
        <div class="hb">
          红包余额￥<span class="integer">{{ startRedbag }}</span><span>.{{ endRedbag }}</span>,下单可抵扣
        </div>


        <div class="item-content-middle" @click="nowArrears" v-if="arrearsData > 0">
          当前欠款{{ arrearsData }}元，前往查看<label class="iconfont icon-youjiantou"></label>
        </div>
        <div v-else class="item-content-middle" >
          当前无欠款
        </div>
        <div class="close">
          <img  src="https://obs.pujian.com/frontend/app/other/hb_close.png" />
        </div>

      </div>
    </div>

    <div class="top-fix">
      <div class="v2-top">
        <div class="search v2-g-w1200 v2-g-flex-row v2-g-flex-space-between">
          <div class="v2-items-center v2-g-flex-row">
            <img class="link-color" src="@/assets-v2/images/logo.png" @click="$router.push({ name: 'v2-home' })"
              style="width: 176px" />
          </div>
          <div class="v2-items-center v2-g-flex-row v2-g-flex-space-between">
            <div class="search-1">
              <input type="text" ref="ss" placeholder="请输入商品名称（拼音首字母）/厂家/医保/适应症" v-model.trim="q" @keyup="assocate"
                @keyup.enter="tojump" @keyup.down="pageDown()" @keyup.up="pageUp()" @focus="showHis(true)"
                @blur="showHis(false)" />
              <i v-if="q && q.length > 0" class="iconfont icon-close search-cl" @click.top="q = null"></i>
              <button class="s" @click="tojump">搜索</button>
              <div class="his" v-if="isShowHis || isMouseOver" @mouseenter="isMouseOver = true"
                @mouseleave="isMouseOver = false">
                <ProductHis :sel="selIndex"></ProductHis>
              </div>
              <div class="associate" v-if="isShowAssociate || isAssociateMouseOver"
                @mouseenter="isAssociateMouseOver = true" @mouseleave="isAssociateMouseOver = false">
                <ProductAssociate ref="associateChild" :sel="selIndex" :word="q"></ProductAssociate>
              </div>
            </div>

            <div class="yhqs">
              <div></div>
            </div>
          </div>
        </div>
        <div class="tips v2-g-w1200" v-if="showJiedanTip">
          <div class="lf">
            <i class="iconfont icon-warning"></i>
            <span>温馨提示：{{ jiedanTip }}</span>
          </div>

          <div class="clt" @click="setshowJiedanTip(false)">
            <i class="iconfont icon-close"></i>
          </div>
        </div>
        <div class="menu v2-g-w1200 v2-g-flex-row" v-if="!$route.path.includes('/v2/details')">
          <div class="menu-item" style="width: 200px">
            <Category :visible.async="false"></Category>
          </div>
          <div class="menu-item" :class="$route.name == 'v2-home' ? 'sel' : ''"
            @click="$router.push({ name: 'v2-home' })">
            <div class="name">
              <span>首页</span>
            </div>
          </div>
            <div class="menu-item" :class="
            $route.name == 'v2-product' && $route.params.id == '7'
              ? 'sel'
              : ''
          " @click="$router.push({ name: 'v2-product', params: { id: '7' } })">
                <div class="name">
                    <span>医保</span>
                </div>
            </div>

            <div class="menu-item" :class="$route.name == 'v2-controlSell' ? 'sel' : ''"
                 @click="$router.push({ name: 'v2-controlSell' })">
                <div class="name">
                    <span>控销</span>
                </div>
            </div>
            <div class="menu-item" :class="
            $route.name == 'v2-product' && $route.params.id == '9'
              ? 'sel'
              : ''
          " @click="$router.push({ name: 'v2-product', params: { id: '9' } })">
                <div class="name">
                    <span>诊卫</span>
                </div>
            </div>
          <div class="menu-item" :class="
            $route.name == 'v2-product' && $route.params.id == '6'
              ? 'sel'
              : ''
          " @click="$router.push({ name: 'v2-product', params: { id: '6' } })">
            <div class="name">
              <span>中药</span>
            </div>
          </div>
<!--          <div class="menu-item" :class="-->
<!--            $route.name == 'v2-product' && $route.params.id == '8'-->
<!--              ? 'sel'-->
<!--              : ''-->
<!--          " @click="$router.push({ name: 'v2-product', params: { id: '8' } })">-->
<!--            <div class="name">-->
<!--              <span>诊所</span>-->
<!--            </div>-->
<!--          </div>-->
          <div class="menu-item" :class="
            $route.name == 'v2-product' && $route.params.id == '10'
              ? 'sel'
              : ''
          " @click="$router.push({ name: 'v2-product', params: { id: '10' } })">
            <div class="name">
              <span>独家</span>
            </div>
          </div>
<!--          <div class="menu-item" :class="$route.name == 'v2-hotProduct' ? 'sel' : ''"-->
<!--            @click="$router.push({ name: 'v2-hotProduct' })">-->
<!--            <div class="name">-->
<!--              <span>特价</span>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
      <div class="flota-btn">
        <div class="btn">
          <img src="../../assets-v2/images/shoping_cart.png" />
          <span>购物车</span>
        </div>
      </div>

      <ul class="left-card">
        <li class="cdfl">返利活动</li>
        <li class="cdfl-info">
          <div class="lin">
            <span>订单：</span><label>￥{{ cardInfo.allTotalMoney || 0 }}</label>
          </div>
          <div class="lin" v-if="cardInfo.cartOrderReturnProportionVO && cardInfo.cartOrderReturnProportionVO.isShow === 1">
            <span>返利：</span>
            <label>￥{{ cardInfo.cartOrderReturnProportionVO.orderReturnProportionAmount }}</label>
            <label>({{ cardInfo.cartOrderReturnProportionVO.orderDiscountMeas }}%)</label>
          </div>
          <div class="lin" v-if="cardInfo.activityNextInfoVO">
            <span>立减：</span>
            <label>￥{{ cardInfo.activityNextInfoVO.type == 0 ? 0 : cardInfo.activityNextInfoVO.reductionMoney }}</label>
            <label>({{ cardInfo.activityNextInfoVO.type == 0 ? 0 : cardInfo.activityNextInfoVO.orderReturnMoneyPercent }}%)</label>
          </div>
          <span class="sep"></span>
          <p class="ntx" v-if="cardInfo.isMax == 1">添加更多返利品种，享更多返利优惠</p>
          <p class="ntx" v-else-if="proportionFilter_1() == 0">
              <span style="padding-bottom: 10px; display: inline-block;" v-if="cardInfo.activityNextInfoVO">
                满￥{{ cardInfo.activityNextInfoVO.thresholdMoney }}整单立减{{ cardInfo.activityNextInfoVO.orderReturnMoneyPercent }}%
              </span>
              <span v-else-if="cardInfo.cartOrderReturnProportionVO">
                选返利商品，满{{cardInfo.cartOrderReturnProportionVO.orderActivityCondMeas}}元，
                最高返{{cardInfo.cartOrderReturnProportionVO.maxOrderDiscountMeas}}%
              </span>
          </p>
          <p class="ntx" v-else>
            再凑￥{{ cardInfo.amountGap }}返利品种，享整单<b>{{ cardInfo.isMax == "-1" ? 3 : proportionFilter_1() + 1 }}%</b>返利
          </p>
          <button type="button" @click.stop="toRebate()" v-if="!cardInfo.activityNextInfoVO">去凑单</button>
        </li>

        <li class="card-wrapper" @click="toShopCard()" @mouseenter="showCardQuick()" @mouseleave="hideCardQuick">
          <div class="it" name="ccc" :class="isAddCard ? 'running' : 'pause'">
            <span>{{ cardInfo.num || 0 }}</span>
          </div>
          <!-- 购物车快捷查看 左侧购物车图标hover时显示 -->
          <div class="show-card" ref="showCard">
            <ShopCard></ShopCard>
          </div>
        </li>
        <li class="order-wrapper" @click="toOrder()">
          <div class="or">
            <i class="iconfont icon-dingdan"></i>
            <p>我的订单</p>
          </div>
          <div class="sep"></div>
          <div class="cus-wrapper">
            <i class="iconfont icon-kefu"></i>
            <p>开票热线</p>
            <div class="detail">
              <div class="line1">
                <p><i class="iconfont icon-phone"></i>开票热线</p>
                <p class="num">023-8807 9999</p>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <div class="left-red-packet" @click="openRedPacket" v-if="isShowLeftRedbag">
        <div class="close" @click.stop="closeRedbag"><i class="iconfont icon-close"></i></div>
        <div :class="isredpacket == true ? 'red-packet-text' : 'red-packet-text'" ref="redpacket">
          {{ Redbag2 }}
        </div>
        <img class="btn animated delay1" src="https://obs.pujian.com/frontend/app/newUser/fbtn.png" />
      </div>

<!--      <ul class="left-jh-fl-hb" v-if="$route.name == 'v2-home'">-->
<!--        <li @click="scrollTo('myplan', 0)" :class="idx == 0 ? 'sel' : ''" name="myplanbtn">-->
<!--          <span>我的计划</span>-->
<!--        </li>-->
<!--        <li class="sep"><span></span></li>-->
<!--        <li @click="scrollTo('rebate', 1)" :class="idx == 1 ? 'sel' : ''" name="rebatebtn">-->
<!--          <span>返利专区</span>-->
<!--        </li>-->
<!--        <li class="sep"><span></span></li>-->
<!--        <li @click="scrollTo('redbag', 2)" :class="idx == 2 ? 'sel' : ''" name="redbagbtn">-->
<!--          <span>新品上市</span>-->
<!--        </li>-->
<!--        <li class="sep"><span></span></li>-->
<!--        <li @click="toTop()" class="to-top">-->
<!--          <i class="iconfont icon-xiangshang"></i>-->
<!--          <span>顶部</span>-->
<!--        </li>-->
<!--      </ul>-->
<!--      <div class="video-wrapper" @click="$router.push({ name: 'v2-video' })"-->
<!--        v-if="$route.name != 'v2-video' && isShowVideoEntrance">-->
<!--        <img src="../../assets-v2/images/video.gif" />-->
<!--      </div>-->
      <RebateList :show="showRebate" @close="showRebate = false"></RebateList>
    </div>
  </div>
</template>


<style scoped lang="less">
@speed: 16s;

.redmodel {
  width: 100%;
  height: 100%;
  top: 0;
  left:0;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 999999;

  .item {
    top: 50% !important;
    left: 50% !important;
    width: 366px;
    height: 469px;
    background: url("../../assets-v2/images/red-packet/hbbj.png")center center;
    background-size: 366px 469px;
    position: absolute;
    inset: 0;
      transform: translate(-50%, -50%);
      .hb{
      position: absolute;
      bottom: 57px;
      text-align: center;
      height: 37px;
      line-height: 37px;
      font-size: 15px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      width: 100%;
      font-weight: 400;
      .integer{
        font-size: 27px;
        font-weight: 600;
      }
    }
    .tb{
      height: 183px;
      width: 278px;
      overflow-y: auto;
      margin:auto;
      margin-top:13px;
      table{
        font-size: 12px;
        .t-h{
          td{
            color: rgba(0,0,0,0.6);
          }

        }
        width: 100%;
        border-top:1px solid #F2F2F2;
        border-right: 1px solid #F2F2F2;
        td{
          height: 27px;
          line-height: 27px;
          color: rgba(0,0,0,0.9);
          &:nth-child(1){
            color: rgba(0,0,0,0.6);
          }
          border-left:1px solid #F2F2F2;
          border-bottom: 1px solid #F2F2F2;
        }
      }
    }
    .close{
      cursor: pointer;
      position: absolute;
      bottom: -52px;
      left:calc(50% - 16px);
      img{
        width: 32px;
        height: 32px;
      }
    }
  }


  .item-head {
    font-family: SourceHanSansCN-Medium;

    margin-top: 109px;

    font-size: 18px;
    font-weight: bold;
color: rgba(0,0,0,0.9);
  }



  .item-content-middle {
    height: 18px;
    line-height: 18px;
    font-size: 13px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #F9F9FF;
    width: 100%;
    position: absolute;
    bottom: 32px;
    cursor: pointer;
  }




}

.top-fix {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1000;
}

.v2-top {
  width: 100%;

  .icon_hi {
    background: url("../../assets-v2/images/sy_hai.png");
  }

  .height41 {
    background: #f2f2f2;
    line-height: 41px;
    height: 41px;
    border: 1px solid #ededed;
  }

  .v2-customer {
    color: #1b45f3;
    margin-right: 8px;
  }

  .v2-link1 {
    a {
      margin: 0 10px;
      text-align: center;
      color: #666666;
    }

    i {
      display: inline-block;
      width: 1px;
      background-color: #999999;
      height: 10px;
    }
  }

  .v2-link2 {
    margin-left: 50px;

    a {
      color: #666666;
      margin: 0 5px;
    }

    i {
      font-size: 16px;
    }
  }

  .tips {
    margin-top: 10px;
    padding: 0 10px;
    line-height: 32px;
    text-align: left;
    background: #ffe6e6;
    display: flex;
    align-items: center;
    height: 32px;
    justify-content: space-between;
    font-size: 0.875rem;
    color: #ff3e3e;
    font-weight: 400;

    .lf {
      display: flex;
      align-items: center;

      i {
        font-size: 1.5rem;
      }
    }

    span {
      vertical-align: top;
      margin-left: 10px;
    }

    .clt {
      cursor: pointer;
      color: #000;

      i {
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }

  .search {
    height: 60px;
    margin: 6px auto;

    .s {
      width: 80px;
      height: 38px;
      background: #3875f6;
      border: none;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }

    .search-1 {
      position: relative;
      height: 40px;
      border: 1px solid #4574fd;

      input {
        width: 620px;
        height: 37px;
        background: #ffffff;

        border: none;
        padding: 0 5px;
      }

      .search-cl {
        position: absolute;
        right: 85px;
        top: calc(50% - 12px);
        cursor: pointer;

        &:hover {
          color: #3875f6;
        }
      }

      .his {
        position: absolute;
        width: 600px;
        height: 400px;
        top: 40px;
        left: 0;
        background: #fff;
        border: 1px solid #ededed;
        box-shadow: 1px 1px 5px #b5b5b5;
        z-index: 6000;
      }

      .associate {
        position: absolute;
        width: 520px;
        height: 400px;
        top: 40px;
        left: 0;
        background: #fff;
        border: 1px solid #ededed;
        box-shadow: 1px 1px 5px #b5b5b5;
        z-index: 6000;
      }
    }

    .yhqs {
      position: relative;
      width: 300px;
      height: 100%;

      div {
        font-size: 40px;
        font-weight: 600;
        text-align: left;
        width: 100%;
        overflow: hidden;
        height: 100%;
        background: url("../../assets-v2/images/640.gif") no-repeat center center;
        background-size: 400px 80px;
        // animation: letter 4s infinite;
        //  text-shadow: 0 0 0px #fff;
        // letter-spacing: 1ch;
        // -webkit-filter: contrast(20);
        //  filter: contrast(20);
        color: #f2f2f2;
      }
    }
  }
}

.menu {
  margin: 16px auto;
  margin-bottom: 1px;
  height: 50px;
  line-height: 50px;
  background-color: #3875f6;
  color: #ffffff;

  span {
    margin-left: 5px;
    font-size: 16px;
    font-weight: 400;
    vertical-align: top;
  }

  .menu-item {
    cursor: pointer;
    position: relative;
    width: 102px;

    .name {
      position: absolute;
      z-index: 10;
      width: 100%;
    }

    &.sel {
      &::after {
        content: "";
        background: url(../../assets-v2/images/menu-sel.png) no-repeat center center;
        background-size: 102px 56px;
        top: -6px;
        left: 0;
        width: 102px;
        height: 56px;
        position: absolute;
        z-index: 0;
      }
    }
  }
}

.flota-btn {
  display: none;
  position: fixed;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
  top: 250px;
  left: 0;
  width: 70px;
  height: 34px;
  background-color: #3875f6;
  color: #fff;

  .btn {
    height: 100%;
    align-items: center;
    display: flex;
  }
}

.left-card {
  position: fixed;
  left: calc(50% - 780px);
  top: 76px;
  z-index: 6000;
  width: 150px;
  background: #ffffff;

  li {
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.0588);
    width: 100%;
    margin-bottom: 10px;
    font-size: 0.875rem;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &.cdfl {
      background-color: #3875f6;
      height: 41px;
      font-size: 1.25rem;
      color: #fff;
    }

    &.cdfl-info {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.898);
      line-height: 18px;
      padding: 0 9px;

      .lin {
        display: flex;
        align-items: center;
        margin-top: 7px;

        span {
          display: inline-block;
          width: 40px;
          font-size: 12px;
        }

        label {
          font-size: 14px;
        }
      }

      .sep {
        height: 1px;

        background: #f2f2f2;
        margin: 9px 0;
      }

      .ntx {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #3875f6;
        text-align: left;
      }

      button {
        margin: 14px 0;
        width: 126px;
        height: 29px;
        padding: 0;
        line-height: 29px;
        background: #f2f7ff;
        cursor: pointer;

        &:hover {
          background-color: #3875f6;
          color: #fff;
        }

        border-radius: 15px 15px 15px 15px;
        font-size: 16px;
        border: none;
        font-family: "PingFang SC-Regular,PingFang SC";
        font-weight: 400;
        color: #3875f6;
      }
    }

    &.card-wrapper {
      margin-top: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
      height: 88px;

      .it {
        position: relative;
        width: 86px;
        height: 55px;
        background: url("https://obs.pujian.com/frontend/applet/icon/cart1.png");
        background-repeat: no-repeat;
        background-size: 6021px;

        &.running {
          animation: bear 3s steps(71) infinite;
          animation-play-state: running;
        }

        &.pause {
          animation: bear2 3s steps(71) infinite;
          animation-play-state: paused;
        }

        //	animation: bear2 3s steps(71) infinite;
        //	animation-play-state: paused;
      }

      .show-card {
        position: absolute;
        background-color: #fff;
        z-index: 6000;
        top: 0;
        left: calc(100% + 10px);
        width: 0;
        height: 0;
        padding: 0;
        border: 1px solid #ededed;
        border-radius: 5px;
        box-shadow: 0 0 5px #ededed;
        overflow: hidden;
        transition: all 0.2s ease-in;
      }

      span {
        display: inline-block;
        position: absolute;
        width: 16px;
        height: 16px;
        top: -5px;
        left: 70px;
        background: #de443b;
        border-radius: 50%;
        color: #fff;
        border: 1px solid #ffffff;
        font-size: 0.75rem;
        line-height: 14px;
      }
    }

    &.order-wrapper {
      padding: 0 9px;

      .or {
        height: 56px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.898);
        cursor: pointer;

        &:hover {
          color: #3875f6;
        }

        i {
          font-size: 1.5rem;
          margin-right: 5px;
        }
      }

      .sep {
        height: 1px;
        background: #f2f2f2;
      }

      .cus-wrapper {
        height: 56px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        &:hover {
          color: #3875f6;
        }

        font-family: PingFang SC-Regular,
        PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.898);
        cursor: pointer;

        i {
          font-size: 1.5rem;
          margin-right: 5px;
        }

        .detail {
          position: absolute;
          box-shadow: 0 0 5px #ededed;
          // border: 1px solid #ededed;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          white-space: nowrap;
          position: absolute;
          width: 0;
          overflow: hidden;
          height: 100px;
          background: #fff;
          border-radius: 9px;
          top: 0px;
          left: calc(100% + 20px);

          color: #0d0d0d;

          text-align: left;
          transition: all 0.5s ease;
          font-size: 1rem;

          .line1 {
            height: 60px;
            font-size: 1rem;

            p {
              &:nth-child(1) {
                i {
                  font-size: 1.13rem;
                  margin-right: 5px;
                  vertical-align: middle;

                  .icon-phone:before {
                    font-size: 1.44rem;
                  }
                }
              }
            }
          }

          .num {
            font-size: 1.19rem;
            height: 30px;
            line-height: 30px;
            padding-left: 30px;
            padding-top: 5px;
            font-weight: 500;
            color: #f08239;
          }
        }

        &:hover {
          .detail {
            width: 200px;
          }
        }
      }
    }
  }
}

.left-red-packet {
  position: fixed;
  left: calc(50% - 760.5px);
  bottom: 280px;
  z-index: 6000;
  width: 100px;
  background: url("https://obs.pujian.com/frontend/app/newUser/fbj.png")5px 0;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 126px;
  cursor: pointer;
  .close{
    position:absolute;
    right: -10px;
    top:-10px;
    color:#666666;

    &:hover{
      color:#b5b5b5;
    }
  }
  .red-packet-text {
    position:absolute;
    font-family: Source Han Sans CN;
    font-size: 22px;
    top: 35px;
    left: 5px;
    width: 100%;
    height: 20px;
    line-height: 20px;
    color: #fff;
    font-weight: bold;
    text-shadow: 0 0 7px rgba(52,255,204,.1);
    background: -webkit-linear-gradient(180deg, #FFFFFF 0%, #FFF239 100%);;
    background: linear-gradient(180deg, #FFFFFF 0%, #FFF239 100%);;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .btn{
    position: absolute;
    bottom: 15px;
    right: -5px;
    width: 100%;
    height: 25px;
    -webkit-animation-name:scaleDraw;
    /*关键帧名称*/
    animation-timing-function:linear;
    /*动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；*/
    -webkit-animation-timing-function:linear;
    /*动画的速度曲线*/
    -webkit-animation-iteration-count:infinite;
    /*动画播放的次数*/
    -webkit-animation-duration:1s;
    /*动画所花费的时间*/
  }
  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
  }

  .delay1 {
    -webkit-animation-delay: 0.6s; //延迟执行动画
    animation-delay: 0.6s;
  }

  @keyframes scaleDraw {

    /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
    0% {
      transform: scale(1);
      /*开始为原始大小*/
    }

    25% {
      transform: scale(0.9);
      /*放大1.1倍*/
    }

    50% {
      transform: scale(0.85);
    }

    75% {
      transform: scale(0.9);
    }

    100% {
      transform: scale(1);
    }
  }

  .red-packet-text1 {
    font-family: Source Han Sans CN;
    font-size: 14px;
    width: fit-content;
    margin: 93px auto 0 auto;
    color: #ffffff;
    font-weight: 500;
    text-shadow: 2px -2px 0px rgba(158, 0, 9, 0.3);
  }
}

.left-jh-fl-hb {
  position: fixed;
  left: calc(50% - 732px);
  bottom: 10px;
  width: 58px;
  height: 252px;
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.0588);
  border-radius: 0px 0px 0px 0px;

  li {
    width: 100%;
    padding: 0 14px;
    height: 63px;
    background: #ffffff;
    font-size: 15px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.sel,
    &:hover {
      color: #3875f6;
    }

    &.sep {
      height: 1px;

      span {
        display: block;
        height: 100%;
        width: 100%;
        background: #f2f2f2;
      }
    }

    &.to-top {
      display: flex;
      flex-direction: column;
      justify-content: center;

      i {
        font-size: 1.2rem;
      }
    }
  }
}

@keyframes bear {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -6021px 0;
  }
}

@keyframes bear2 {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -6021px 0;
  }
}

@media only screen and(max-width:1560px) and (min-width: 1316px) {
  .left-jh-fl-hb {
    left: 0;
  }
}

@media only screen and(max-width:1316px) {
  .left-jh-fl-hb {
    display: none;
  }
}

@media only screen and(max-width:1560px) and (min-width: 1500px) {
  .left-card {
    left: 0;
  }
}

@media only screen and(max-width:1500px) {
  .flota-btn {
    display: block;

    &:hover~.left-card {
      left: 0;
    }
  }

  .left-card {
    left: -150px;

    transition: all 0.2s ease-in-out;

    &:hover {
      left: 0;
    }

    //left: calc(50% - 700px)
  }
}

@media only screen and(max-width:1600px) {
  .left-red-packet {
    // top: 530px;
    transition: all 0.1s ease-out;
    left: 0;
    bottom: 35px;
  }
}

.video-wrapper {
  cursor: pointer;
  position: fixed;
  bottom: 0px;
  right: calc(50% - 600px);
  transform: translateX(100%);

  img {
    width: 160px;
  }
}

@media screen and(max-width:1440) {
  .video-wrapper {
    img {
      width: 80px;
    }
  }
}

@media screen and(max-width:1520) and(min-width:1440) {
  .video-wrapper {
    img {
      width: 120px;
    }
  }
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";
import ProductHis from "./product-his.vue";
import ProductAssociate from "./product-associate-word";
import Category from "../category";
import ShopCard from "./shop-card-list.vue";
import RebateList from "@/components-v2/rebate/index";
import { AccountStorage } from "@services/account";
import { rebate } from "../../mixin/rebate";

const accountStorage = new AccountStorage();
export default {
  mixins: [rebate],
  components: { ProductHis, ProductAssociate, Category, ShopCard, RebateList },
  data() {
    return {
      startRedbag: null,
      summaryData:[],
      endRedbag: null,
      isshowRedbag: false,
      isredpacket: true,
      idx: -1,
      q: null,
      isShowHis: false, //是否显示历史
      isMouseOver: false, //是否浏览在 历史区域内
      isShowAssociate: false, //是否显示联想区域
      isAssociateMouseOver: false, //是否在浏览联想区域
      selIndex: -1, //pageup,pagedown 事件选择
      jiedanTip: "",
      showRebate: false, //是否显示凑单返利
      arrearsData: null
    };
  },
  created() {
    if (this.$route.name == "v2-search") {
      this.q = this.$route.params.q;
    }

    this.getTip();
    if (this.$route.name == "v2-home") {
      this.getIsShowVideoEntrance();
      this.findCardList(); //首页或去购物车列表，就版本切换到新版本时，更新下购物车
    }
  },

  watch: {
    "$route.params": function () {
      if (this.$route.name == "v2-search") {
        this.q = this.$route.params.q;
      }
    },
    q: function (newV, oldV) {
      if (newV != oldV) {
        this.selIndex = -1; //每次查询联想次 把上下选中重置
      }
    },
    Redbag2(val) {
      let str = val.toFixed(2);
      this.startRedbag = str.split(".")[0]
      this.endRedbag = str.split(".")[1]
    }
  },
  computed: {
    ...mapGetters({
      isAddCard: "isAddCard",
      showJiedanTip: "showJiedanTip",
      isShowVideoEntrance: "isShowVideoEntrance",
      isShowLeftRedbag:"isShowLeftRedbag",
      Redbag2: "Redbag2"
    }),
  },
  mounted() {
    this.getRedPacketData();
    this.$nextTick(() => {
      if (this.$refs.redpacket && this.$refs.redpacket.offsetWidth > 65) {
        this.isredpacket = false;
      }
    });
    window.addEventListener("scroll", this.scrollLiandong);
    //刷新页面时候，重新获取购物车数据
    window.addEventListener("load", () => {
      if (this.$route.name != "v2-home" && this.$route.name != "v2-shopcard") {
        this.findCardList();
      }
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollLiandong);
  },
  methods: {
    ...mapActions([
      "findCardList",
      "setshowJiedanTip",
      "getIsShowVideoEntrance",
      "getRedPacketData",
      "setIsShowLeftRedbag"
    ]),
    async getYearList(){
      const res = await this.$getHttpClient().get(
                "/pjyy-deepexi-user-center/api/v1/report/reportCustomerPromotion/getListByYear" +
                "?year=" + new Date().getFullYear()
            );
            if (res.data.code === "200") {
                this.summaryData = res.data.data;
            }
    },
    closeRedbag(){
      this.setIsShowLeftRedbag(false)
    },
    openRedPacket() {
      this.isshowRedbag = true;
      this.getYearList();
      this.getArrears();
    },
    //立即还款
    async nowArrears() {
      let account = accountStorage.getCacheUserInfo().account;
      const URL = `/pjyy-deepexi-pay-center/rpc/v2/openPay/getWebPayLink1?erpId=${account}&orderSource=5`;
      const result = await this.$getHttpClient().get(URL);
      if (result.data.code == 200) {
        window.open(result.data.data);
      }
    },
    //获取欠款
    async getArrears() {
      let account = accountStorage.getCacheUserInfo().account;
      const URL = `/pjyy-deepexi-erp-api-center/api/v1/erp/creditStanding/${account}`;
      const result = await this.$getHttpClient().get(URL);
      if (result.data.code == 200) {
        this.arrearsData = result.data.data.currentSumDebit;
      }
    },
    //滚动条和左边３个按钮联动
    scrollLiandong() {
      if (this.$route.name == "v2-home") {
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        if (scrollTop <= 424) {
          this.idx = -1;
        }
        // this.regionLiandong(document.getElementsByName("myplan")[0], 0);
        // this.regionLiandong(document.getElementsByName("rebate")[0], 1);
        // this.regionLiandong(document.getElementsByName("redbag")[0], 2);
      }
    },
    regionLiandong(el, btnIndex) {
      const offset = el.getBoundingClientRect(); // vue中，使用this.$el获取当前组件的根元素

      const offsetTop = offset.top;
      // 进入可视区域
      if (offsetTop >= 0 && offsetTop < 168) {
        this.idx = btnIndex;
      }
    },
    showCardQuick() {
      if (this.cardInfo.ocCartLists && this.cardInfo.ocCartLists.length > 0) {
        this.$refs.showCard.style.width = "400px";
        this.$refs.showCard.style.height = "400px";
        this.$refs.showCard.style.padding = "5px 10px";
      }
    },
    hideCardQuick() {
      this.$refs.showCard.style.width = "0";
      this.$refs.showCard.style.height = "0";
      this.$refs.showCard.style.padding = "0";
    },
    async getTip() {
      if (accountStorage.getCacheUserInfo()) {
        let code = accountStorage.getCacheUserInfo().account;
        const url = `/pjyy-deepexi-user-center/api/v1/customer/manager/getSendTimeByVencusNo?userCode=${code}`;
        let r = await this.$getHttpClient().post(url);

        if (r.data.code == "200") {
          this.jiedanTip = r.data.data;
          if (!this.jiedanTip) {
            this.setshowJiedanTip(false);
          }
        }
      }
    },
    toTop() {
      let timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;

        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    scrollTo(name, index) {
      window.removeEventListener("scroll", this.scrollLiandong);
      let top = 424;

      let myplanH = document.getElementsByName("myplan")[0].offsetHeight;
      let rebateH = document.getElementsByName("rebate")[0].offsetHeight;
      if (index == 0) {
        top = 424;
      }

      if (index == 1) {
        top = top + myplanH;
      }
      if (index == 2) {
        top = top + myplanH + rebateH;
      }
      window.scrollTo({
        top: top,
        behavior: "smooth" // 平滑过渡
        //  block: "nearest"    // 上边框与视窗顶部平齐
      });
      this.idx = index;
      //滚动完毕重新添加监控事件
      let temp = 0;
      let $this = this;
      setTimeout(function judge() {
        let temp1 = document.documentElement.scrollTop || document.body.scrollTop;
        if (temp != temp1) {
          temp = temp1;
          setTimeout(judge, 100);
        } else {
          window.addEventListener("scroll", $this.scrollLiandong);
          temp = null;
        }
      }, 100);
    },

    //跳转
    tojump() {
      this.$refs.ss.blur();
      if (this.q == null || this.q == "") {
        return;
      }
      if (this.selIndex >= 0) {
        this.$refs["associateChild"].tojump_enter();
        this.isShowAssociate = false;
      } else {
        this.$router.push({ name: "v2-search", params: { q: this.q } });
      }
    },
    toOrder() {
      let userInfo = accountStorage.getCacheUserInfo();
      if (userInfo && userInfo.type == 1){
          this.$messageTip("您尚未成为正式会员，无法进入该页面", 'error');
          return;
      }
      this.$router.push({ name: "v2-order", params: { status: "0" } });
    },
    toShopCard() {
      this.$router.push({ name: "v2-shopcard" });
    },
    toRebate() {
      this.showRebate = true;
      // this.$router.push({ name: 'v2-product', params: { id: '2' } });
    },
    //焦点时显示 历史

    showHis(flag) {
      if (flag) {
        if (this.q == null || this.q == "") {
          this.isShowHis = flag;
        } else {
          this.isShowAssociate = flag;
        }
      } else {
        this.isShowHis = flag;
        this.isShowAssociate = flag;
      }
    },

    //输入查询词时联想
    assocate() {
      if (this.q == null || this.q == "") {
        this.isAssociateMouseOver = false;
        this.isShowAssociate = false;
        this.isShowHis = true;
      } else {
        this.isShowHis = false;
        this.isShowAssociate = true;
      }
    },
    pageDown() {
      //默认显示10条
      if (this.selIndex < 9) {
        this.selIndex = this.selIndex + 1;
      }
    },
    pageUp() {
      if (this.selIndex > 0) {
        this.selIndex = this.selIndex - 1;
      }
    }
  }
};
</script>
